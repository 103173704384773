import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from '../../util/reactIntl';
import config from '../../config';
import { TopbarContainer } from '..';
import { Footer, PageBootstrap, NamedLink,} from '../../components';
import { propTypes } from '../../util/types';
import { Container, Row, Col } from 'react-bootstrap';

import './FeaturedGuidesLandingPage.scss';
import metaImage from '../../assets/ogp.jpg';
import guide1Image from './img_FeaturedGuidesLandingPage/guide1.jpg';
import guide2Image from './img_FeaturedGuidesLandingPage/guide2.jpg';
import guide3Image from './img_FeaturedGuidesLandingPage/guide3.jpg';
import guide4Image from './img_FeaturedGuidesLandingPage/guide4.jpg';
import guide5Image from './img_FeaturedGuidesLandingPage/guide5.jpg';
import guide6Image from './img_FeaturedGuidesLandingPage/guide6.jpg';
import guide7Image from './img_FeaturedGuidesLandingPage/guide7.jpg';



const Guide = ({ name, image, description, youtubeURL }) => (
  <Col lg={6} md={6} sm={12} xs={12} className="guideblock">
    <img src={image} alt={name} className="guidePhoto" />
    <p className="guide-text text-center text-uppercase mt-3">kammui guide</p>
    <h2>{name}</h2>
    <p className="description-text text-center mb-2">{description}</p>
    <div className="iframe-wrapper">
      <iframe
        className="youtube-iframe"
        src={youtubeURL}
        title={name}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
    <div className="separator"></div>
  </Col>
);


const FeaturedGuidesLandingPageComponent = props => {

  const { currentUser, intl } = props;
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${metaImage}`;

  const title= intl.formatMessage({id: 'GuidesPage.title'})
  const descriptionOne= intl.formatMessage({id: 'GuidesPage.descriptionOne'})
  const descriptionTwo= intl.formatMessage({id: 'GuidesPage.descriptionTwo'})

  const inquiryButton= intl.formatMessage({id: 'GuidesPage.inquiryButton'})
  const experiencesButton=intl.formatMessage({id: 'GuidesPage.experiencesButton'})

  const inquiryTitle=intl.formatMessage({id: 'GuidesPage.inquiryTitle'})
  const inquiryDescriptionOne=intl.formatMessage({id: 'GuidesPage.inquiryDescriptionOne'})
  const inquiryDescriptionTwo=intl.formatMessage({id: 'GuidesPage.inquiryDescriptionTwo'})
  
  const guidesArray = [
    {
      id: 1,
      name: intl.formatMessage({id: 'GuidesPage.guideOneName'}),
      description: intl.formatMessage({id: 'GuidesPage.guideOneDescription'}),
      image: guide1Image,
      youtubeURL: "https://www.youtube.com/embed/vlGZturNFXo"
    },
    {
      id: 2,
      name: intl.formatMessage({id: 'GuidesPage.guideTwoName'}),
      description: intl.formatMessage({id: 'GuidesPage.guideTwoDescription'}),
      image: guide2Image,
      youtubeURL: "https://www.youtube.com/embed/VHBG3VLHm_Q"
    },
    {
      id: 3,
      name: intl.formatMessage({id: 'GuidesPage.guideThreeName'}),
      description: intl.formatMessage({id: 'GuidesPage.guideThreeDescription'}),
      image: guide3Image,
      youtubeURL: "https://www.youtube.com/embed/_gyjKRMKddw"
    },
    {
      id: 4,
      name: intl.formatMessage({id: 'GuidesPage.guideFourName'}),
      description: intl.formatMessage({id: 'GuidesPage.guideFourDescription'}),
      image: guide4Image,
      youtubeURL: "https://www.youtube.com/embed/DfR6tirnYIA"
    },
    {
      id: 5,
      name: intl.formatMessage({id: 'GuidesPage.guideFiveName'}),
      description: intl.formatMessage({id: 'GuidesPage.guideFiveDescription'}),
      image: guide5Image,
      youtubeURL: "https://www.youtube.com/embed/DfR6tirnYIA"
    },
    {
      id: 6,
      name: intl.formatMessage({id: 'GuidesPage.guideSixName'}),
      description: intl.formatMessage({id: 'GuidesPage.guideSixDescription'}),
      image: guide6Image,
      youtubeURL: "https://www.youtube.com/embed/lL_yckzSKfk"
    },
    {
      id: 7,
      name: intl.formatMessage({id: 'GuidesPage.guideSevenName'}),
      description: intl.formatMessage({id: 'GuidesPage.guideSevenDescription'}),
      image: guide7Image,
      youtubeURL: "https://www.youtube.com/embed/n4GKbIAquBE"
    }
  ];


  return (
    <PageBootstrap
       description={schemaDescription}
       title={schemaTitle}
       facebookImages={[{ url: metaImage, width: 1200, height: 630 }]}
       twitterImages={[{ url: `${config.canonicalRootURL}${metaImage}`, width: 600, height: 314 }]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'GuidesPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >

      <TopbarContainer bigText={'Guides'} />
      <Container className="pt-6">
        <Row>
          <Col lg={9} md={8} sm={8} xs={12} className="hero">
            <h2>{title}</h2>
            <p>{descriptionOne}</p>
            <p>{descriptionTwo}</p>
            <div className="landing-page-separator"></div>
          </Col>    
        </Row>
       
        <Row className="guidesListWrapper">
          {guidesArray.map((guide,index) => (
            <Guide 
              key={index}
              name = {guide.name}
              description = {guide.description}
              image = {guide.image}
              youtubeURL = {guide.youtubeURL}>
            </Guide>
          ))}
        </Row>
        <Row>
          <div className="landing-page-separator-end"></div>
        </Row>

        <Row className="browse-wrapper">
          <Col lg={8} md={8} sm={8} xs={12} className="browse">
            <div className="button-textlink">
              <NamedLink name="GuidesPage" className="btn btn-link">{experiencesButton}</NamedLink>
            </div>
          </Col>
        </Row>
        
      </Container>

      <Footer showBanner={false} />
    </PageBootstrap>

  );
};


FeaturedGuidesLandingPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
}

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    currentUser,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(FeaturedGuidesLandingPageComponent);
